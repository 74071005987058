/* global document */

import naja from 'naja';

const COL_WIDTH_3 = 'col-3';
const COL_WIDTH_4 = 'col-4';
const DISPLAY_NONE = 'd-none';
const HIDDEN = 'hidden';
const OPENED = 'opened';
const ORDER_FIRST = 'order-1';
const ORDER_SECONDARY = 'order-2';
const CLINCHECK_DETAIL_PARAM = 'clincheckId';
const IGNORE = 'js-patient-url';

const clincheckDetail = document.querySelector('.js-clincheck-detail');
const clincheckRow = document.querySelectorAll('.js-clincheck-row');
let elementsToDisplay;
let elementsToHide;

const filterExpand = document.querySelector('.js-filter-expand');
const filterRows = document.querySelectorAll('.js-filter-row');
const filterRowsHidden = document.querySelectorAll('.js-filter-row-hidden');

function showDetail(hideFilters = true) {
	clincheckDetail.classList.add(OPENED);
	clincheckRow.forEach((row) => {
		row.classList.add(OPENED);
	});
	elementsToHide.forEach((element) => {
		element.classList.add(HIDDEN);
	});
	elementsToDisplay.forEach((element) => {
		element.classList.remove(DISPLAY_NONE);
	});
	filterRows.forEach((row) => {
		row.classList.add(ORDER_FIRST, COL_WIDTH_4);
	});
	if (hideFilters) {
		filterRowsHidden.forEach((row) => {
			row.classList.add(DISPLAY_NONE, ORDER_SECONDARY, COL_WIDTH_3);
		});
	}
}

function hideDetail() {
	clincheckDetail.classList.remove(OPENED);
	clincheckRow.forEach((row) => {
		row.classList.remove(OPENED);
	});
	elementsToHide.forEach((element) => {
		element.classList.remove(HIDDEN);
	});
	elementsToDisplay.forEach((element) => {
		element.classList.add(DISPLAY_NONE);
	});
	filterRows.forEach((row) => {
		row.classList.remove(ORDER_FIRST, COL_WIDTH_4);
	});
	filterRowsHidden.forEach((row) => {
		row.classList.remove(DISPLAY_NONE, ORDER_SECONDARY, COL_WIDTH_3);
	});
}

if (filterExpand) {
	filterExpand.addEventListener('click', () => {
		filterExpand.classList.toggle('text-primary');
		filterExpand.classList.toggle(OPENED);
		filterRowsHidden.forEach((row) => {
			row.classList.toggle(DISPLAY_NONE);
		});
	});
}

function init(container) {
	elementsToDisplay = document.querySelectorAll('.js-display-on-open');
	elementsToHide = document.querySelectorAll('.js-hide-on-open');

	const clincheckDetailClose = container.querySelector('.js-clincheck-close');
	if (clincheckDetailClose) {
		clincheckDetailClose.addEventListener('click', (e) => {
			e.preventDefault();
			hideDetail();
			const { url } = clincheckDetailClose.dataset;
			naja.makeRequest('GET', url);
		});
	}

	const clincheckApprove = container.querySelector('.js-clincheck-approve');
	if (clincheckApprove) {
		clincheckApprove.addEventListener('click', () => {
			const { url } = clincheckApprove.dataset;
			naja.makeRequest('GET', url).then(() => {
				hideDetail();
			});
		});
	}

	const clincheckOpenLink = container.querySelectorAll('.js-clincheck-open');
	if (clincheckOpenLink.length > 0) {
		clincheckOpenLink.forEach((link) => {
			link.addEventListener('click', (e) => {
				if (e.target.classList.contains(IGNORE)) {
					return;
				}

				const { url } = link.dataset;
				naja.makeRequest('GET', url).then(() => {
					if (!clincheckDetail.classList.contains(OPENED)) {
						showDetail();
					}
				});
			});
		});
	}

	const loadMoreBtn = container.querySelector('.js-load-more');
	if (loadMoreBtn) {
		const loader = loadMoreBtn.querySelector('.js-loader')?.cloneNode(true);
		loadMoreBtn.addEventListener('click', () => {
			if (loadMoreBtn.classList.contains('loading')) {
				return;
			}

			loader.classList.remove('d-none');
			loadMoreBtn.classList.add('loading');
			loadMoreBtn.innerHTML = '';
			loadMoreBtn.append(loader);
			loadMoreBtn.append('Loading');
			const { url } = loadMoreBtn.dataset;
			naja.makeRequest('POST', url).then(() => {
				loadMoreBtn.innerText = 'Load more';
				loadMoreBtn.classList.remove('loading');
			});
		});
	}

	if (clincheckDetail && clincheckDetail.classList.contains(OPENED)) {
		showDetail(false);
	}
}

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
	init(event.detail.snippet);
});

init(document);

function checkURL() {
	const params = (new URL(document.location)).searchParams;
	const clincheckParam = params.get(CLINCHECK_DETAIL_PARAM);

	if (clincheckParam) {
		showDetail();
	}
}

document.addEventListener('DOMContentLoaded', () => {
	checkURL();
});
