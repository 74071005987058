/* global document */

const flashes = document.querySelectorAll('.js-flash');

function flashFade(flash) {
	flash.classList.remove('alert-visible');
	setTimeout(() => {
		flash.remove();
	}, 200);
}

flashes.forEach((flash) => {
	setTimeout(() => {
		flashFade(flash);
	}, 4500);

	const exits = flash.querySelectorAll('.js-flash-close');
	if (exits) {
		exits.forEach((exit) => {
			exit.addEventListener('click', (event) => {
				event.preventDefault();
				flashFade(flash);
			});
		});
	}
});
