/* global document */
import naja from 'naja';
import netteForms from 'nette-forms';

import BootstrapModalExtension from './js/BootstrapModalExtension';
import './js/clincheck';
import './js/flash';
import './js/forms';
import './js/input';
import './js/multiselect';
import './js/tooltip';
import './scss/index.scss';

naja.formsHandler.netteForms = netteForms;
naja.registerExtension(new BootstrapModalExtension());

document.addEventListener('DOMContentLoaded', naja.initialize.bind(naja));

function initLoader(remove	= false) {
	const loader = document.querySelector('.js-loader-page');
	if (loader && !remove) {
		loader.classList.remove('d-none');
	} else if (loader && remove) {
		loader.classList.add('d-none');
	}
}

naja.addEventListener('start', () => {
	initLoader();
});

naja.addEventListener('error', () => {
	initLoader(true);
});

naja.snippetHandler.addEventListener('afterUpdate', () => {
	initLoader(true);
});
