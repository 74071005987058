/* global document */
import '@popperjs/core';

import { Tooltip } from 'bootstrap';

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');

if (tooltipTriggerList) {
	tooltipTriggerList.forEach((tip) => new Tooltip(tip));
}
