/* global document */
import netteForms from 'nette-forms';

function groupOrElement(element) {
	return element.closest('.input-group') || element;
}

netteForms.showFormErrors = function showFormErrors(form, errors) {
	const displayed = [];
	let focusElem;

	form.querySelectorAll('.is-invalid').forEach((el) => {
		el.classList.remove('is-invalid');
	});
	form.querySelectorAll('.js-form-error').forEach((el) => {
		el.remove();
	});

	const { elements } = form;
	for (let i = 0; i < elements.length; i += 1) {
		const element = elements[i];
		const group = groupOrElement(element);
		const parent = group.parentNode;
		element.classList.remove('is-invalid');
		group.classList.remove('is-invalid');
		parent.classList.remove('is-invalid');
		parent.querySelectorAll('.js-form-error').forEach((el) => {
			el.remove();
		});
	}

	const siblings = errors.map(({ element }) => groupOrElement(element).nextSibling);

	for (let i = 0; i < errors.length; i += 1) {
		const { element, message } = errors[i];

		if (!displayed.some((error) => error.element === element && error.message === message)) {
			const group = groupOrElement(element);
			const parent = group.parentNode;
			const feedbackEl = document.createElement('span');
			feedbackEl.classList.add('invalid-feedback', 'js-form-error');
			feedbackEl.innerText = message;

			element.classList.add('is-invalid');
			group.classList.add('is-invalid');
			parent.classList.add('is-invalid');
			parent.insertBefore(feedbackEl, siblings[i]);

			displayed.push({ element, message });

			if (!focusElem && element.focus) {
				focusElem = element;
			}
		}
	}

	if (focusElem) {
		focusElem.focus();
	}
};

netteForms.initOnLoad();

document.addEventListener('change', (e) => {
	if (!e.target.classList.contains('is-invalid')) {
		return;
	}

	const element = e.target;
	if (element.classList.contains('form-control') || element.classList.contains('form-select')) {
		if (netteForms.validateControl(element, null, true)) {
			const group = groupOrElement(element);
			const parent = group.parentNode;
			element.classList.remove('is-invalid');
			group.classList.remove('is-invalid');
			parent.classList.remove('is-invalid');
			parent.querySelectorAll('.js-form-error').forEach((el) => {
				el.remove();
			});
		}
	}
});
