/* global document */
import naja from 'naja';

const DEFAULT_PLACEHOLDER = 'Select';
const SELECTED = 'selected';

function selectElement(option, condition, array, displayElement, erase) {
	if (condition) {
		option.classList.add(SELECTED);

		if (!array.find((i) => i.id === option.id)) {
			array.push({
				id: option.id,
				text: option.innerText,
				value: option.innerText,
			});
		}
	} else {
		option.classList.remove(SELECTED);
		// eslint-disable-next-line no-param-reassign
		array = array.filter((el) => el.id !== option.id);
	}

	if (array.length >= 1) {
		// eslint-disable-next-line no-param-reassign
		displayElement.innerText = array.map((opt) => opt.text).join(', ');
		displayElement.appendChild(erase);
		displayElement.classList.add(SELECTED);
	} else {
		// eslint-disable-next-line no-param-reassign
		displayElement.innerText = DEFAULT_PLACEHOLDER;
		displayElement.classList.remove(SELECTED);
	}
	return array;
}

function deselectAll(array, optGroups, inner, displayElement, select) {
	// eslint-disable-next-line no-param-reassign
	array = [];
	// eslint-disable-next-line no-param-reassign
	displayElement.innerText = DEFAULT_PLACEHOLDER;
	displayElement.classList.remove(SELECTED);

	function deselect(element, isOption = false) {
		element.forEach((el) => {
			if (isOption) {
				// eslint-disable-next-line no-param-reassign
				el.selected = false;
			}
			el.classList.remove(SELECTED);
		});
	}

	if (optGroups.length > 0) {
		optGroups.forEach((group) => {
			const options = group.querySelectorAll('option');
			const optionWraps = inner.querySelectorAll('.multiselect-option');
			const groupTitles = inner.querySelectorAll('.multiselect-title');

			deselect(options, true);
			deselect(optionWraps);
			deselect(groupTitles);
		});
	} else if (optGroups.length === 0) {
		const options = select.querySelectorAll('option');
		options.forEach((option) => {
			// eslint-disable-next-line no-param-reassign
			option.selected	= false;
		});
		const optionWraps = inner.querySelectorAll('.multiselect-option');
		deselect(optionWraps);
	}

	return array;
}

function checkGroup(group, title) {
	const elements = group.querySelectorAll('.multiselect-option');
	if (elements.length === group.querySelectorAll('.multiselect-option.selected').length) {
		title.classList.add(SELECTED);
	} else {
		title.classList.remove(SELECTED);
	}
}

function createElement(el, cls) {
	const element = document.createElement(el);
	element.classList.add(cls);
	return element;
}

function autoFormSubmit(wrap) {
	const select = wrap.previousElementSibling;
	if (select.classList.contains('js-auto-submit')) {
		naja.uiHandler.submitForm(select.form);
	}
}

function init() {
	const selects = document.querySelectorAll('.js-form-select');

	if (selects) {
		selects.forEach((select) => {
			const erase = createElement('span', 'multiselect-erase');
			const display = createElement('span', 'multiselect-input');
			const dropdown = createElement('div', 'multiselect-dropdown');
			const inner = createElement('div', 'multiselect-inner');
			const label = createElement('label', 'form-label');
			const optGroups = select.querySelectorAll('optgroup');
			const wrap = createElement('div', 'multiselect');
			let selectedOptions = [];

			display.classList.add('form-control', 'text-truncate');
			display.innerHTML = DEFAULT_PLACEHOLDER;
			label.innerHTML = select.getAttribute('aria-label');
			select.classList.add('d-none');

			display.addEventListener('click', () => {
				wrap.classList.toggle('opened');
				if (!wrap.classList.contains('opened')) {
					autoFormSubmit(wrap);
				}
			});

			document.addEventListener('click', (e) => {
				if (
					!wrap.contains(e.target)
					&& !dropdown.contains(e.target)
				) {
					const wasOpened = wrap.classList.contains('opened');
					wrap.classList.remove('opened');
					if (wasOpened) {
						autoFormSubmit(wrap);
					}
				}
			});

			if (optGroups.length > 0) {
				optGroups.forEach((group, groupId) => {
					const groupTitle = createElement('div', 'multiselect-title');
					const groupWrap = createElement('div', 'multiselect-group');
					let groupSelected;

					groupTitle.innerText = group.label;
					groupTitle.id = `${select.id}-${groupId}`;
					groupWrap.appendChild(groupTitle);

					groupTitle.addEventListener('click', () => {
						const options = group.querySelectorAll('option');
						const optionWrap = groupWrap.querySelectorAll('.multiselect-option');

						groupSelected = !groupSelected;

						optionWrap.forEach((optionElement) => {
							selectedOptions = selectElement(
								optionElement,
								groupSelected,
								selectedOptions,
								display,
								erase,
							);
						});

						options.forEach((option) => {
							// eslint-disable-next-line no-param-reassign
							option.selected = groupSelected;
						});

						checkGroup(groupWrap, groupTitle);
					});

					const options = group.querySelectorAll('option');

					options.forEach((option, optionId) => {
						const optionWrap = createElement('div', 'multiselect-option');

						optionWrap.innerText = option.innerText;
						optionWrap.id = `${select.id}-${groupId}_${optionId}`;

						optionWrap.addEventListener('click', () => {
							const { selected } = option;
							// eslint-disable-next-line no-param-reassign
							option.selected = !selected;
							// groupSelected = !groupSelected;

							selectedOptions = selectElement(
								optionWrap,
								option.selected,
								selectedOptions,
								display,
								erase,
							);
							checkGroup(groupWrap, groupTitle);
						});
						groupWrap.appendChild(optionWrap);

						if (option.selected) {
							selectedOptions = selectElement(optionWrap, true, selectedOptions, display, erase);
							checkGroup(groupWrap, groupTitle);
						}
					});
					inner.append(groupWrap);
				});
			} else if (optGroups.length === 0) {
				const options = select.querySelectorAll('option');
				options.forEach((option, optionId) => {
					const optionWrap = createElement('div', 'multiselect-option');

					optionWrap.innerText = option.innerText;
					optionWrap.id = `${select.id}-${optionId}`;

					optionWrap.addEventListener('click', () => {
						const { selected } = option;
						// eslint-disable-next-line no-param-reassign
						option.selected = !selected;

						selectedOptions = selectElement(optionWrap, option.selected, selectedOptions, display, erase);
					});
					inner.appendChild(optionWrap);

					if (option.selected) {
						selectedOptions = selectElement(optionWrap, true, selectedOptions, display, erase);
					}
				});
			}

			erase.addEventListener('click', () => {
				selectedOptions = deselectAll(selectedOptions, optGroups, inner, display, select);
			});

			wrap.appendChild(display);
			wrap.appendChild(dropdown);
			dropdown.appendChild(inner);
			select.insertAdjacentElement('afterend', wrap);
			display.insertAdjacentElement('beforebegin', label);
			display.appendChild(erase);
		});
	}
}

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
	const { snippet } = event.detail;
	if (snippet.id === 'snippet--filter') {
		init();
	}
});

init();
