/* global document */

import naja from 'naja';

import debounce from '../utils/debounce';

const dateInputWraps = document.querySelectorAll('.js-input-date');

if (dateInputWraps) {
	dateInputWraps.forEach((inputWrap) => {
		inputWrap.addEventListener('click', () => {
			const input = inputWrap.querySelector('input');
			if (input) {
				if (input.type !== 'date') {
					input.type = 'date';
				}
				input.showPicker();
				inputWrap.classList.add('active');
				input.addEventListener('input', (e) => {
					e.stopImmediatePropagation();
					naja.uiHandler.submitForm(input.form);
				});
			}
		});
	});
}

function init(refocus) {
	const searchInput = document.querySelectorAll('.js-search-input');
	const searchErase = document.querySelectorAll('.js-search-erase');

	if (searchInput) {
		searchInput.forEach((input) => {
			if (refocus) {
				const end = input.value.length;

				input.setSelectionRange(end, end);
				input.focus();
			}

			if (input.value) {
				input.parentElement.classList.add('has-value');
			} else	{
				input.parentElement.classList.remove('has-value');
			}

			input.addEventListener('keyup', (e) => {
				e.preventDefault();
				debounce(() => {
					naja.uiHandler.submitForm(input.form);
				});
			});
		});
	}

	if (searchErase) {
		searchErase.forEach((erase) => {
			erase.addEventListener('click', (e) => {
				e.preventDefault();
				const input = erase.parentElement.querySelector('input');
				input.value = '';
				input.focus();
				naja.uiHandler.submitForm(input.form);
			});
		});
	}
}

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
	const { snippet } = event.detail;
	if (snippet.id === 'snippet--filter') {
		init(true);
	} else {
		init();
	}
});

init();
